
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fc8459158-ddd3-4ace-aaec-44886225bd7a?alt=media&token=f2693677-9b71-4f9a-8e2c-0ea0e7a993f3"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fe9f5be39-fd8b-4112-95c2-6531f9f9b778?alt=media&token=722573ba-e5e0-42f4-8d82-764483e8b23e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F52edefd5-26fd-45df-b99f-73ced67a7b4a?alt=media&token=509fac25-0391-4699-8a94-e8ea1b8a9d07"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Ffe4f23f4-3522-4d35-bf06-97e9ac731053?alt=media&token=d40a88b0-38c9-4926-9f3a-40a7a30f78fc"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Ffcdaa593-4dfe-4dca-acae-be3159dde7ca?alt=media&token=249656cf-7011-488f-ad70-6177d0b87a92"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F72e0b6d9-5b16-4b07-8e5c-4444889f7ee8?alt=media&token=7d069cfa-29a5-44aa-afa3-c17e605e1194"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fa630b19b-8bdd-4c8c-9f68-66b40008eaff?alt=media&token=4ccbe7ca-125b-4673-a223-afb41e0870d0"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fad755833-5af6-457d-8557-c1879d17943a?alt=media&token=9f9c19c5-307a-4aad-ba5d-9d9f48903731"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F23378264-9416-4ef4-965f-c14fe573b20e?alt=media&token=85e69047-db5c-4d66-b2bb-f974789912e6"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F26f2337f-2f4a-42cf-8415-1747d14e359d?alt=media&token=b546ad78-0ec0-4ab6-86c0-3b5dfcc2a280"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fa56c9a02-fd14-4d5a-a195-35033f027edc?alt=media&token=e0ad0053-c855-4c34-958f-92128bba9c57"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F72afb9f6-ddc6-4ce4-8aa6-aa37cac65153?alt=media&token=bd02b516-5822-477b-86f9-7df7db2fb9b8"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Ff598f33a-01bc-4c81-8606-5e0261cc3514?alt=media&token=99095c3b-a493-4223-9bae-6f542cc96f85"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F123852ac-83c7-423d-8f52-8a261fbe96ee?alt=media&token=d5fc8b18-24b4-4289-8890-cfbf2ea06c77"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F663d3e35-e2d3-4737-aef4-99748c2cede3?alt=media&token=c31cc588-3528-44f0-aac1-99deb1024f33"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F0cfe000a-4b88-41a2-a4a7-50a36ee0cff1?alt=media&token=aad539af-96fe-4776-89b9-d2cc1b242c06"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Ffe1e2f37-8ce1-48be-8980-e4ba5eefe48b?alt=media&token=ed62b9bb-e737-4c53-93a1-6f580ac60958"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fa9b27d40-e293-464b-b077-ed8d4407d7c9?alt=media&token=859f296b-5e6a-4dcf-8a37-81eba1ca80db"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fafc7420a-54aa-4b80-b2fa-b04fba768d2a?alt=media&token=5c6895c7-cc55-434c-8d7d-2abad191a86a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fdc251257-3ee6-46b2-9ee0-80495c6fcb7b?alt=media&token=08cb758e-e9c4-4e2f-92c1-6e7404345568"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F5d1ce69b-6647-4caa-91b8-79723486f098?alt=media&token=f0e772bf-c92d-4218-86b2-852400a930c1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2F7b252c82-3e48-41f9-aca6-5c664c5863c7?alt=media&token=2acc56f9-8f8f-4bb2-b920-7fa83ce0a482"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2Fdf000878-0754-4569-9a3a-94c79618b05e?alt=media&token=500a0c71-9a82-4856-8ca8-74b76371c0ca"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU9ES%2FDIVU9ESMAT.pdf?alt=media&token=cfa8772c-bb68-417e-b9f4-7378f2e40012' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
